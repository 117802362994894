<template>
  <div>
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '新闻中心',
          desc: '关注华信互联，掌握行业最新动态',
          show_desc: true,
        }"
        :img="require('@/assets/EcologicalRecruitment/banner1.png')"
      >
        <!-- 插槽 -->
      </commonTop>
      <!--  -->
      <NewsList @scrollToTop="scrollToTop" />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import NewsList from "@/components/NewsCenter/NewsList.vue";

export default {
  name: "EcologicalRecruitment",
  components: {
    commonTop,
    isHeader,
    isFooter,
    NewsList,
  },
  data() {
    return {};
  },
  mounted() {
    // this.Message({
    //   message:"message"
    // });

    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
  methods: {
    scrollToTop() {
      this.$refs.scrollBox.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.isTab {
  margin-left: -1.9rem;
}
@media screen and (max-width: 1024px) {
  .isTab {
    margin-left: -20px !important;
  }
}
</style>
