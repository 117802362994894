<template>
  <div class="NewsListView" ref="NewsListView">
    <div class="list">
      <div
        v-runshow
        class="item d_f_a"
        v-for="(item, index) in showList"
        :key="index"
        @click="intoNewsDetail(item.url)"
      >
        <div class="imgView d_f_a j_c">
          <img
            class="img"
            :src="item.coverImg"
            alt=""
            referrerpolicy="no-referrer"
          />
        </div>
        <div class="contentView">
          <p class="title">{{ item.title }}</p>
          <p class="time">{{ item.time }}</p>
          <p class="digest">{{ item.digest }}</p>
          <p class="tip">查看详情</p>
        </div>
      </div>
    </div>
    <div class="pages d_f_a j_c_c">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="pageNum"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import newsData from "../../../public/News.json";

export default {
  emits: ["scrollToTop"],
  data() {
    return {
      newsList: [],
      showList: [],
      pageNum: 1,
      total: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.getNewsList();
  },
  mounted() {
    this.setNewsData();
  },
  methods: {
    getNewsList() {
      this.$axios
        .post("http://122.96.151.110:9875/wxPublish/getPublishList", {
          offset: 0,
          count: 20,
          no_content: 0,
        })
        .then((r) => {
          let response = r.data.item;
          let list = [];
          response.forEach((item) => {
            let obj = {};
            obj.title = item.content.news_item[0].title;
            obj.digest = item.content.news_item[0].digest;
            obj.time = this.$tools.transformTime(item.update_time);
            console.log(obj.time);
            obj.url = item.content.news_item[0].url;
            obj.coverImg = item.content.news_item[0].thumb_url;
            list.push(obj);
          });

          console.log(list);
        });
    },
    setNewsData() {
      let list = newsData.list;
      list.sort((obj1, obj2) => {
        return new Date(obj2.time).getTime() - new Date(obj1.time).getTime();
      });
      this.total = list.length;
      this.newsList = list;
      this.changePage(1);
    },
    changePage(exPage) {
      let startIndex = 10 * (exPage - 1);
      let endIndex = 10 * exPage;
      this.showList = this.newsList.slice(startIndex, endIndex);
      this.$emit("scrollToTop");
    },
    intoNewsDetail(url) {
      window.open(url, "_bank");
    },
  },
};
</script>

<style scoped lang="scss">
.NewsListView {
  .list {
    .item:nth-child(2n) {
      background-color: #f3f3f3;
    }
    .item {
      padding: 0.8rem 1.9rem;
      margin-bottom: 0.16rem;
    }
    .imgView {
      margin-right: 0.5rem;
      border-radius: 0.08rem;
      overflow: hidden;
      width: 5rem;
      height: 2.4rem;
      font-size: 0;
      .img {
        width: 100%;
      }
      .img:hover {
        transform: scale(1.1);
      }
    }
    .contentView {
      flex: 1;
      cursor: pointer;
      .title {
        font-size: 0.22rem;

        color: #333;
      }
      .time {
        font-size: 0.14rem;
        color: #999;
        margin: 0.25rem 0;
      }
      .digest {
        font-size: 0.18rem;
        color: #666;
        line-height: 2;
      }
      .tip {
        display: none;
      }
    }
    .contentView:hover {
      .title,
      .time,
      .digest {
        color: rgb(68, 124, 198);
      }
    }
  }
  .pages {
    padding: 0.8rem 1.9rem;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #1aa858;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #1aa858;
    }
    /deep/ .active {
      color: white !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .list {
      .item {
        padding: 20px;
        margin-bottom: 0.16rem;
        display: block;
      }
      .imgView {
        margin-right: 0;
        border-radius: 0;
        overflow: hidden;
        width: 100%;
        margin-bottom: 20px;
        height: 3.3rem;
        .img {
          width: 100%;
        }
        .img:hover {
          transform: scale(1.1);
        }
      }
      .contentView {
        cursor: pointer;
        .title {
          font-size: 0.32rem;

          color: #333;
        }
        .time {
          font-size: 0.22rem;
          color: #999;
          margin: 0.25rem 0;
        }
        .digest {
          font-size: 0.28rem;
          color: #666;
          line-height: 2;
        }
        .tip {
          display: block;
          text-align: right;
          color: #666;
          margin-top: 0.25rem;
        }
      }
      .contentView:hover {
        .title,
        .time,
        .digest {
          color: rgb(68, 124, 198);
        }
      }
    }
  }
}
</style>
